body{
    width: 100%;
}
.sectionn {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.swiper3{
width: 100%;
    height: fit-content;
}
.sub{
width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}


.project-title {
    background-color: var(--light-grey-A);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'ArbFONTS-DG-Heaven-Regular';
    color: var(--white);
    height: fit-content;
    padding-right: 1.5em;
    padding-left: 1.5em;
}
  .project-title img {
      width: 10em;
      height: 10em;
  }
  .project-title p {
      font-size: 3rem;
  }
  
@media (max-width:1080px)
{
    
    .project-title img {
            width: 5em;
            height: 5em;
        }
                .project-title p {
                    font-size: 2rem;
                }
       
}
