
body{
    width: 100%;
}
.projectimg{
    width:100%; /*500 px 28.25em */
    height:100% ; /*300px 15.75em*/
}

.project{
    align-self: flex-start; 
    
}
.imageparent{
    display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    width: 100%;
}

.contain{
    display: grid;
    grid-template-columns: repeat(auto-fit , 28.25em);
    grid-auto-flow: row;
    gap: 2rem;
    justify-content: center;
    margin-right:2%;
    margin-left:2%;

}
.badge{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-start;
   margin-right:-0.53rem ;
   margin-top:-2.8rem ;
    z-index: 5;
    position: absolute;
        top: 0;
        right: 0;
   
}

.special{
color: var(--white);
    font-family: 'DG-Heaven-Light';
    font-size: 18px;
    position:absolute;
    margin-right: -10px;
    margin-top: -10px;
 
    
}
.bdge-img{
    width: 85px;
    height: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text1{
    font-family: 'ArbFONTS-DG-Heaven-Regular';
    font-size: 21.73px;
    color: var(--drak-blue);
    margin-top: 10px;
}

.text2 .text3{
  font-family: 'DG-Heaven-Light';
}

.text2{
    color: var(--grey);
    width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
   
}

.text3{
    color: var(--light-grey-B1);
}

.map-title{
    background-color: var(--med-blue);
    width: 100%;
    text-align: center;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:3.125em;
        padding: 30px;
    color:var(--white);
}

.hovered .projectimg:hover{
    -webkit-filter: brightness(70%);
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
}
.detailsbutton{
    position: absolute;
    display: flex;
        justify-content: center;
        align-items: center;
    text-decoration-line: none;
       
}
.hidden{
    display: none;
}
.btn{
    background-color: transparent;
    color: var(--white);
    border-radius: 0;
    border: 1px solid white;
}
.btn:hover {
    background-color: transparent;
    color: var(--white);
    border-radius: 0;
    border: 1px solid white;

}
.btn:focus{
    color: var(--med-blue) !important;
    background-color: var(--white) !important;
    border:none !important;
    
}

Link{
}
@media (max-width: 1080px){
    .contain {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
            grid-auto-flow: row;
            justify-content: center;
            gap: 10px;
    
        }
                .map-title {
                    
                    font-size: 2.5em;
                    height: 50px;
                }
                .special {
                    color: var(--white);
                    font-family: 'DG-Heaven-Light';
                    font-size: 12px;
                    position: absolute;
                    margin-right: -10px;
                    margin-top: -5px;
        
        
                }
        
                .bdge-img {
                    width: 55px;
                    height: 43px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                                .badge {
                               
                                    margin-right: -0.5rem;
                                    margin-top: -1.6rem;
                                
                                }
                                                                .text1 {
                                                                    font-family: 'ArbFONTS-DG-Heaven-Regular';
                                                                    font-size: 13.73px;
                                                                    color: var(--drak-blue);
                                                                    margin-top: 10px;
                                                                    margin-bottom: 5px;
                                                                }
                                                                .text2{
                                                                    font-size: 9.73px;
                                                                    margin-bottom: 5px;
                                                                }
                                                                .text3{
                                                                    font-size: 8.73px;
                                                                }
      
}
@media (max-width: 50.625em) {

           .contain {
                    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    
           }

}
@media (max-width:38.125em) {


        .bdge-img {
            width: 50px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .badge {
          
            margin-right: -0.8755rem;
            margin-top: -0.9rem;
           
    
        }
        .special{
            font-size:8px ;
            margin-right: -5px;
                margin-top: -4px;
        }
                  .contain {
                      /* display: grid;
                      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                      grid-auto-flow: row;
                      justify-content: center;
                      gap: 10px; */
        
                  }
}
@media (max-width: 25.625em) {
    .contain {
            /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
           
    
        }
}