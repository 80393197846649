body{
    font-family: var(--DG-heaven-reg);
}
/* .homemap{
    position: relative;
        width: 100%;
        padding-bottom: 30%;
        height: 640px;
} */
.map-title{
    background-color: var(--white) !important;
    color: var(--dark-blue) !important;
}
.pins-name{
    color: var(--dark-grey);
}
.pins{
    gap: 10%;
}
@media (max-width:1080px){
    .pins img{
        width: 40px;
        height: 40px;
    }
    /* .homemap {
            position: relative;
            width: 100%;
            padding-bottom: 30%;
            height: 340px;
        } */
}