.menuu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.menu-num{
    font-size: 1em;
}
.item {
    
    color: var(--white);
    text-decoration: none;
    font-family: var(--DG-heaven-reg);
    font-size: 1.8em;
    width: 220px;
    border-bottom: 2px solid var(--white);
    width: 80%;
    text-align: center;
    padding-bottom: 0.8em;
}
.lst{
    border-bottom: none;
}
.item:focus{
    color: var(--lighter-blue);
}

.phoneicon{
    width: 1.3em;
    height:1.3em;
                    margin-right: 0.5em;
}

.contact{
    text-decoration-line: none;
}
.whatsappp,
.instagramm {
    width: 1.4em;
    height: 1.4em;
                    margin-right: 0.8em;
    

}
@media (max-width:768px) {
    .item {
            color: var(--white);
            text-decoration: none;
            font-family: var(--DG-heaven-reg);
            font-size: 1em;
            width: 220px;
            border-bottom: 1px solid var(--white);
            width: 90%;
            text-align: center;
            padding-bottom: 18px;
        }
                .phoneicon {
                    width: 14px;
                    height: 15px;
                    margin-right: 0.5em;
                }
                                .whatsappp,
                                .instagramm {
                                    width: 14px;
                                    height: 15px;
                                    margin-right: 0.5em;
                
                
                                }
                                                                .contact {
                                                                    text-decoration-line: none;
                                                                    font-size: 0.8em;
                                                                }
}

