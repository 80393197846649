.body{
  width: 100%;
}
.mySwiper2  {
    width: 41.875em;
    height: 22.5625em;
    margin-bottom: 10px;
  
}
.mySwiper{
    width:41.875em ;
    height: 89px;
}

.mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.swiper-slide .stages-img {
  display: block;

  width: 100%;
  height: 100%;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.sidebar{
   width: 21.5rem; 
   background-color: var(--med-blue);
   display: flex;
   flex-direction: column;
   align-items: center;
   color:var(--white);
  gap :30px;
  font-size:1.3em;
  height: 670px;
  /* margin-left: 2rem; */
}
.sidelogo{
    width: 180px;
        height: 157px;
}
.phone-num{
  font-family: 'FuturaBT';
border-bottom: 3px solid white;
padding-bottom: 10px;
text-decoration: none;
}
.phone-num span{
  font-size: 35px;

}
.whatsapp-sidebar,
.instagram-sidebar {
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;

}
.sectionn{
display: flex;
flex-direction: row;
}
.project-title{
    background-color: var(--light-grey-A);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
  font-family: 'ArbFONTS-DG-Heaven-Regular';
color:var(--white);
}
.project-title p{
  font-size: 3rem;
}
.sub{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* justify-content: center; */
}

@media (max-width:1080px){
    .sidebar {
        display: none;
      }


.swiper-slide .stages-img {
display: block;
width: 100%;
height: 100%;
}

.mySwiper2 {
width:95vw;
height: 50vw;
margin-bottom: 10px;

}

.mySwiper {
width: 95vw;
height: 19%;
}


         .project-title p {
           font-size: 2rem;
         }
       /* .sub{
        width: min-content;
        margin: auto;
       } */
}
@media (max-width:48em){

swiper-slide .stages-img {
    display: block;
    width: 100%;
    height: 100%;
  }

.mySwiper2 {
    width: 95vw;
    height: 45vh;
    margin-bottom: 10px;

  }

.mySwiper {
    width: 95vw;
    height: 15%;
  }
    .sidebar {
      display: none;
    }
        /* .mySwiper2 {
          width: 31.875em;
          height: 32.5625em;
          margin-bottom: 10px;
    
        }
    
        .mySwiper {
          width: 31.875em;
          height: 59px;
        } */
       
}
@media (max-width:33.75em){
  swiper-slide .stages-img {
      display: block;
      width: 100%;
      height: 100%;
    }
  
    .mySwiper2 {
      width: 95vw;
      height: 55vw;
      margin-bottom: 10px;
  
    }
  
    .mySwiper {
      width: 95vw;
      height: 15%;
    }
  /* .mySwiper2 {
      width: 19.875em;
      height: 19.5625em;
      margin-bottom: 10px;
  
    }
  
    .mySwiper {
      width: 19.875em;
      height: 59px;
    } */
         
}
@media (max-width:23.4375em){
    swiper-slide .stages-img {
        display: block;
        width: 100%;
        height: 100%;
      }
    
      .mySwiper2 {
        width: 95vw;
        height: 75vw;
        margin-bottom: 10px;
    
      }
    
      .mySwiper {
        width: 95vw;
        height: 17%;
      }
  /* .mySwiper2 {
      width: 15.875em;
      height: 15.5625em;
      margin-bottom: 10px;
  
    }
  
    .mySwiper {
      width: 15.875em;
      height: 39px;
    }
     */
}
@media (max-width:17.1875em) {
  swiper-slide .stages-img {
      display: block;
      width: 100%;
      height: 100%;
    }
  
    .mySwiper2 {
      width: 95vw;
      height:95vw;
      margin-bottom: 10px;
  
    }
  
    .mySwiper {
      width: 95vw;
      height: 17%;
    }
  /* .mySwiper2 {
      width: 13.875em;
      height: 13.5625em;
      margin-bottom: 10px;
  
    }
  
    .mySwiper {
      width: 13.875em;
      height: 39px;
    } 
        .mySwiper2 {
          width: 50vw;
          height: 70vh;
          margin-bottom: 10px;
    
        }
    
        .mySwiper {
          width: 50vw;
          height: 18vh;
        }
    
        .mySwiper .swiper-slide {
          width: 100%;
          height: 100%;
          opacity: 0.6;
        }
    
        .swiper-slide .stages-img {
          display: block;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }*/
}

