body{
    color: var(--med-blue);
}
.classs{
    width: 100%;
        height: 50vh;
}
.mainimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.who-us{
    /* background-color: var(--med-blue); */
    position: absolute;
    top:11em;
    right: 0;
   padding-top: 1.875em;
   padding-bottom: 1.25em;
   padding-right: 6.25em;
   color: var(--white);
  font-family: 'ArbFONTS-DG-Heaven-Regular';
  /* font-weight: lighter; */
   /* height: 126px; */
}
.whoustitle{
   font-size: 2.5em;
}
.back2{
    background-color: var(--med-blue);
   width: 35%;
height: 100px;
position: absolute;
    top: 12em;
    right: 0;
    mix-blend-mode: multiply;
        opacity: 1;

}

.break{
    width: 100%;
    background-color:var(--light-grey-A);
    height: 18px;
    margin-top: 10px;
}
.introduction{
    color:var(--med-blue);
    font-size: 1.1em;
    margin-top: 20px;
    line-height:1.8em;
}
.vision-title{
   display: flex;
   flex-direction: row;
   align-items: center;
    color:var(--med-blue) ;
}
.vision-icon{
    width: 5em;
    height: 5em;
}
.vision{
    margin-top: 2em;
}
.vertical-line{
    width: 2px;
    height: 7em;
    background-color: var(--med-blue);
    margin-left: 10px;
}
.p1{
    display: flex;
    flex-direction: column;

}
.title{
    margin-left:8%;
    font-size: 2em;
}

.text{
    color: var(--med-blue);
    font-size: 1em;
    line-height: 37px;
}
.logos{
    display: flex;
    flex-direction: row;
    gap: 2.5%;
    text-align: center;
}
.icon{
    width: 6em;
    height: 3em;
}
.pres-text{
    line-height: 55px;
    font-size: 23px;
}
   .back {
       width: 100%;
       height: 122px;
   }

@media (max-width:1080px){
    .whoustitle {
            font-size: 2em;
        }
    .classs {
            width: 100%;
            height: 35vh;
        }
                .who-us {
                 
                    top: 8.5em;
                    right: 0;
                  
                    padding-right: 3.25em;
                
                }
.back2{
    width: 40%;
                    top: 9em;
}
.icon {
    width:  4em;
    height: 3em;
}
.logos p {
    font-size: 0.9em;
}


}
@media (max-width:768px){
    .title {
            font-size: 1.5em;
        }
    .p1 {
            display: flex;
            flex-direction: row;
           
    
        }
    .introduction {
            font-size: 0.8em;
            margin-top: 20px;
            line-height: 1.8em;
        }
    .whoustitle {
            font-size: 1.7em;

        }
    .classs {
            width: 100%;
            height: 35vh;
        }
    
        .who-us {
    
            top: 7.5em;
            right: 0;
         
           
    
            padding-right: 2em;
    
        }
.back2 {
        width: 40%;
        top: 8em;
    } .back{
        height: 60px;
        width: 100%;
    }
  .text {
      font-size: 0.8em;
      line-height: 1.8em;
  }
.vision-icon {
    width: 4em;
    height: 4em;
}
.title {
    margin-left: 7%;
}
.logos {
    
    gap:5%;
}
}
@media (max-width:481px){
    .classs {
            width: 100%;
            height: 25vh;
        }

    .whoustitle {
            font-size: 1.5em;
        }
                .back2 {
                    width: 50%;
                    top: 6em;
                }
.who-us{
            padding-right: 1.5em;
            top: 5.5em;
  padding-top:1em;
}
.back2{
        height: 60px;
    }
        .icon {
            width: 3em;
            height:2em;
        }
    
        .logos p {
            font-size: 0.5em;
        }

                 .title {
                     font-size: 1em;
                 }
                                 
              
}
@media (max-width:300px){
    .whoustitle {
            font-size: 1em;
        }
    .icon {
            width: 1.5em;
            height: 1em;
        }
                 .logos p {
                     font-size: 0.4em;
                 }
                                 .logos {
                
                                     gap: 1%;
                                 }
}


