@font-face {
  font-family:'ArbFONTS-DG-Heaven-Regular' ;
  src: url(Assets/Fonts/ArbFONTS-DG-Heaven-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family:'DG-Heaven-Light' ;
  src: url(Assets/Fonts/ArbFONTS-DG-Heaven-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family:'FuturaBT' ;
  src: url(Assets/Fonts/FUTURAH.TTF);
  font-display: swap;
}
@font-face {
  font-family:'Montserrat-Bold' ;
  src: url(Assets/Fonts/Montserrat-Bold.ttf);
  font-display: swap;
}


:root{
  /* colors */
  --white: #FFFFFF;
  --med-blue: #004DA5;
  --drak-blue: #004DA7;
  --light-blue: #1961B1;
  --lighter-blue:#008EFF;
  --dark-grey: #626366;
  --grey:#818181;
  --med-grey:#999999;
  --description-grey:#808080;
  --light-grey-B1: #B1B3B5;
  --light-grey-B0: #B0B0B0;
  --light-grey-A: #AEB0B2;

  /* Fonts */
  --DG-heaven-reg:'ArbFONTS-DG-Heaven-Regular';
  --DG-heaven-light:'DG-Heaven-Light';
  --FuturaBT-heavy:'FuturaBT';


}
 body {
   margin: 0;
   font-family: 'ArbFONTS-DG-Heaven-Regular';

 }

 code {
   font-family: 'ArbFONTS-DG-Heaven-Regular';
 }