.main-div{
    width: 100%;
    margin: 0;
  

}
.swip{
    margin: auto;
}
.mainimgg {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.classss {
    width: 100%;
    height: 50vh;
}

.sub2 {
    width: 41.875em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.details {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.right {
    justify-self: flex-start;
}
.left {
    justify-self: flex-end;
}

.details-title {
    font-size: 2em;
}

.details-subtitle {
    color: var(--description-grey);
    font-size: 1.1em;
            text-align: center;
}

.details-subtitle span {
    font-size: 1.5em;
    color: var(--description-grey);
    font-family: 'FuturaBT';
    margin-left: 10px;
}

.description {
    width: 41.875em;
}

.description-title {
    font-size: 2em;
    align-self: flex-start;
}

.description-subtitle {
    font-size: 1.0625em;
    color: var(--description-grey);
    line-height: 2em;
}

.checkicon {
    width: 25px;
    height: 25px;
}

.adv span {
    color: var(--description-grey);
    font-family: 'ArbFONTS-DG-Heaven-Regular';
    font-size: 14px;
    margin-right: 15px;
}

.advantages {
    width: 41.875em;
    display: grid;
        grid-template-columns: repeat(3, 0.5fr);
        /* Adjust the number of columns as needed */
        gap: 10px;
}

.adv {
    margin-bottom: 5px;
}

.pdflink {
    text-decoration-line: none;
    color: var(--description-grey);
}

.pdf {
    align-self: right;
}

.pdfimage {
    width: 62px;
    height: 82px;
}

.design {
    width: 41.875em;
    height: 48px;
    background-color: var(--med-blue);
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.design2 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.plusicon {
    width: 19px;
    height: 19px;
    margin-left: 15em;
    margin-right: 15px;
}

.design:hover {
    cursor: pointer;
}

.area {
    margin-top: 15px;
    font-size: 18px;
}

.design span {
    font-size: 23px;
}

.mapp {

        height: 30vh;
        /* Default height for mobile devices */
    }

    @media (min-width: 1080px) {
        .mapp {
                    width: 41.875em;
            height: 60vh;
            /* Adjusted height for desktop devices */
        }
               
    }




.vid {
    width: 41.875em;
}

.vid iframe {
    width: 100%;
}


.planimg {
    width: 100%;
}

.hide {
    display: none;
}

.projectimg:hover {
    -webkit-filter: brightness(70%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.overlay {
    width: 100vw;
    height: 100vh;
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
 .planimg-div {
    width: 41.875em;

 }

@media (max-width:1080px) {

    .sub2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    .description {
        width: fit-content;
    }



    
    .details-title {
        font-size: 1.5em;
    }
        .description-title {
            font-size: 1.5em;
        }

    .details-subtitle {
        font-size: 1.1em;
    }

    .details-subtitle span {
        font-size: 1.5em;

        margin-left: 10px;
    }

    .description-subtitle {
        font-size: 0.8em;
        color: var(--description-grey);
        line-height: 2em;
    }

    .design {
        width: 100%;
        height: 48px;
        background-color: var(--med-blue);
        color: var(--white);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
    }

    .design2 {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

   .vid {
       width: 100%;
   }
   

   .vid iframe {
       width: 100%;
    padding-bottom: 0px;
   }

   
        .planimg-div{
            width: 90%;

        }

   

    .plusicon {

        margin-left: 30%;
        margin-right: 15px;
    }
}

@media (max-width:48em) {
    .advantages {
        width:100% ;
            display: grid;
                grid-template-columns: repeat(3, 1fr);
                /* Adjust the number of columns as needed */
                gap: 1px;
                /* Adjust the gap between items */
        }
    .adv {
            margin-bottom: 2px;
        }
    .adv span {
            font-size: 10px;
            margin-right: 5px;
        }
.checkicon{
        width: 15px;
            height: 15px;
}
    .details {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

   
    .details-title {
        font-size: 1.2em;
    }
          .description-title {
              font-size: 1.2em;
          }

    .details-subtitle {}


    .details-subtitle span {
        font-size: 1em;

        margin-left: 10px;
    }

    .description-subtitle {
        font-size: 0.8em;
        color: var(--description-grey);
        line-height: 2em;
    }

   

    .design {
        width: 100%;
        height: 48px;
        background-color: var(--med-blue);
        color: var(--white);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
    }
                 .planimg-div {
                     width: 80vw;
        
                 }
    .design2 {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .plusicon {
        width: 19px;
        height: 19px;
        margin-left: 35%;
        margin-right: 15px;
    }



    .area {
        margin-top: 15px;
        font-size: 10px;

    }

    .design span {
        font-family: 'Montserrat-Bold';
        font-size: 15px;
    }




}

@media (max-width:375px) {
   
    .details-title {
            font-size: 1em;
        }
    
        .details-subtitle {
            font-size: 0.8em;
        }
                .description-title {
                    font-size: 1.em;
                }
    
    
        .details-subtitle span {
            font-size: 1.55em;
    
            margin-left: 10px;
        }
    .advantages{
        gap: 2px;
    }
  
        .design {
        width: 100%;
            height: 48px;
            background-color: var(--med-blue);
            color: var(--white);
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;
        }
    
        .planimg-div {
            width:80vw;
    
        }
                 .adv span {
                     font-size: 10px;
                 }

}
@media (max-width:300px){
    .plusicon {
    
            margin-left: 25%;
            margin-right: 15px;
        }
}