


.pin-desc-title{
    font-family: var(--DG-heaven-reg);
    font-size: 2em;
}
.map-container {
    width: 100%;
    height: 30vh;
    /* Default height for mobile devices */
}

@media (min-width: 1080px) {
    .map-container {
        height: 60vh;
        /* Adjusted height for desktop devices */
    }
}