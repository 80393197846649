.footer {
    background-color: var(--med-blue);
    width: 100%;
    height: 100%;
    color: white;
position: relative;
    font-family: 'DG-Heaven-Light';
    margin-top: 15px;
}


Form {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

Form .fc {
    background-color: #1961B1;
    margin-bottom: 10px;
    border: none;
    color: white;
    font-family: 'DG-Heaven-Light';

}

Form .formbtn {
    background: #1961B1 !important;
    border-radius: 12px !important;
    opacity: 1 !important;
    border: none;

    width: 35%;
    height: 52px;
    font-size: 1.3em;
}

Form .formbtn:hover {

    border: none;

    width: 40%;
    height: 52px;
}

Form .fc::placeholder {
    color: white;
    font-size: 20px;
}

Form [type=text]:focus {


    color: white;
    background-color: #1961B1;
}

Form [type=text]:focus::placeholder {


    color: transparent;
}

.name,
.numb {
    height: 60px;
}

.ask {
    height: 110px;
}

.part2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20%;
}

.footerlogo {
    width: 19.875em;
    height: 14.9375em;

}

.leftarrow {
    width: 20px;
    height: 39px;

}

.part1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.nums {
    font-family: 'FuturaBT';
    font-size: 1.575em;
    padding-right: 1.25em;
    /* margin-left: 1.25em; */

}
/* 
.toast {
    position: relative;
    z-index: 100000;
    margin: auto;

} */

.hdr {}

.alertdiv {
    height: 100px;


}

.book {
    font-size: 1.875em;
    margin-left: 1.25em;
    padding-top: 0.3125em;
}

.onee {
    border-left: 3px solid white;
    padding-left: 1.25em;
}

.whatsapp,
.instagram {
    width: 2.0625em;
    height: 2.0625em;
    justify-content: flex-end;

}

.whatsapp {
    margin-left: .5em;
}

.part4 {
    gap: 3%;
    padding-bottom: 3em;
    justify-content:center;
}

.contactt {
    text-decoration-line: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.contactt a{
    text-decoration: none;
}

.contact-txt {
    font-size: 1vw;
    color: white;
    font-family: var(--FuturaBT-heavy);
}

.conntact-txt {
    font-size: 0.8vw;
    font-family: var(--DG-heaven-reg);
}

.contact-icon {
    width: 1.5em;
    height: 2em;
    margin-right: 0.6em;
}
.part3-parag{
    text-align: center;
    align-self: start;
 
}
.part3-title{
    margin-bottom: 2em;
    font-size: 1.2em;
}
.part3{
margin-top: 7em;
}
.toast-div{
position: absolute;
    z-index: 50;
    top: 60%;
    left:20%;
}
.toast{
   position: relative;
   height: 100%;
   width: 100%;
}
@media (max-width:1080px) {
    .contact {
            margin-top: 5px;
        }
    .footerlogo {
        display: none;
    }

    .part2 {
        gap: 0;
    }

    Form {
        width: 100%;
    }

    .leftarrow {
        width: 10px;
        height: 29px;

    }

    .book {
        font-size: .8em;
        margin-left: .5em;
        padding-top: 0.8125em;
    }

    .nums {
        font-family: 'FuturaBT';
        font-size: 1em;
        /* margin-left: 1.25em; */

    }

    .whatsapp,
    .instagram {
        width: 1.0625em;
        height: 1.0625em;
        justify-content: flex-end;

    }

    .whatsapp {
        margin-left: 0px;
    }

    .nums {
        padding-right: 0;
        font-size: 0.9em;


    }

    .onee {
        padding-left: 0.5em;
    }
        .part4 {
    
            padding-bottom: 3em;
            justify-content: space-between;
        }
    
    
        .contact-txt {
            font-size: 1.5vw;
            color: white;
            font-family: var(--FuturaBT-heavy);
        }
    
        .conntact-txt {
            font-size: 1vw;
            font-family: var(--DG-heaven-reg);
        }
    
        .contact-icon {
            width: 0.8em;
            height: 1em;
            margin-right: 0.2em;
        }
                .toast-div {
                    position: absolute;
                    z-index: 50;
                    top: 60%;
                    left: 32%;
                }
}

@media (max-width:768px) {
.toast-div {
        position: absolute;
        z-index: 50;
        top: 65%;
        left: 20%;
    }
    Form .formbtn:hover {

    border: none;

    width: 60%;
    height: 45px;
}
    Form .formbtn {
            background: #1961B1 !important;
            border-radius: 7px !important;
            opacity: 1 !important;
            border: none;
            font-size: 1em;

    
            width: 50%;
            height: 45px;
        }
    Form .fc::placeholder {
            color: white;
            font-size: 10px;
        }
    .part3-title {
            margin-bottom: 1em;
            font-size: 0.8em;
            margin-right: 0.2em;
        }
    .part3-parag{
    font-size: 0.5em;
    }
    .book {
        font-size: .7em;
        margin-left: .5em;
        margin-top: 0.3em;
        padding-top: 1em;
        margin-top: 1em;
    }

    .leftarrow {
        width: 15px;
        height: 15px;
        margin-top: 0.5em;

    }

    .onee {
        border-left: 3px solid white;
        padding-left: 1em;
    }

    .part4 {
       
        
        padding-bottom: 3em;
        justify-content: space-between;
    }


    .contact-txt {
        font-size:1.7vw;
        color: white;
        font-family: var(--FuturaBT-heavy);
    }

    .conntact-txt {
        font-size: 1.2vw;
        font-family: var(--DG-heaven-reg);
    }

    .contact-icon {
        width: 0.8em;
        height: 0.8em;
        margin-right: 0.4em;
    }

    .insta {
        display: none;
    }

}

@media (max-width:481px) {
    .toast-div {
            position: absolute;
            z-index: 50;
            top: 65%;
            left:2%;
        }
}
@media (max-width:360px) {
    .toast-div {
            position: absolute;
            z-index: 50;
            top: 66%;
            left:0;
        }
    .part1 {
        display: flex;
        flex-direction: column;
    }

    .nums {
        font-size: 1em;
    }
}