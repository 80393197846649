body {

    width: 100%;
    font-family: 'ArbFONTS-DG-Heaven-Regular';

}

.back {

    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: #808080;
    mix-blend-mode: multiply;
    opacity: 1;
    height: 126px;
}

.swiper {
    width: 100%;
    height: 100%;

}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.mySwiperr .swiper-slide .pc {
    display: block;
    width: 100%;
    height: 100%;
}

.noback {
    display: none;
}


.counter {
    background-color: var(--grey);
    width: 100%;
}

.num {
    font-family: 'FuturaBT';
    font-size: 1.125em;
    /* padding-left:1em ; */
}

.txt {
    font-family: 'ArbFONTS-DG-Heaven-Regular';
    font-size: 0.5em;
    margin-right: 0.9375em;
    /* padding-left: 5em; */
}

.content {
    width: 100%;
    background-color: var(--grey);
}

.home-icon {
    width: 2.75em;
    height: 2.125em;
    /* margin-right:2em;*/
    margin-left: 0.5em;



}

.newest {
    width: 100%;
    text-align: center;
    background-color: var(--white);
    color: var(--med-blue);
    padding-top: 9px;
    padding-bottom: 2px;



}

.top {
    font-size: 2.5em;
    /* margin-bottom: 2.1875em; */
}

.bottom {
    font-size: 1.1em;
}

@media (min-width: 1080px) {

    .swiper {
        width: 100%;
        height: 75vh;

    }


    .mySwiperr .swiper-slide .pc {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .back {
        width: 100%;
        height: 122px;
    }

    .newest {
        padding: 5px;
    }

    .top {
        font-size: 2.125em;
        margin-bottom: 0.3em;
    }

    .bottom {
        font-size: 0.9em;
    }

    .num {
        font-family: 'FuturaBT';
        font-size: 2.3em;
    }

    .txt {
        font-family: 'ArbFONTS-DG-Heaven-Regular';
        font-size: 1em;
        margin-right: 0.9375em;
    }
}

@media (max-width:48em) {
    .home-icon {
        width: 1.75em;
        height: 1.125em;
    }

    .back {
        height: 122px;
        width: 100%;
    }


}