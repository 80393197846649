body {
    font-family: var(--DG-heaven-reg);
}

.navv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    opacity: 1;
    width: 100%;

    z-index:6 ;


}
.logo-div{
    margin-right: 7%;
  
}


.logo {

    width: 14em;

}

.hero_section {
    position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    display: flex;
    align-items: center;
    height: 126px;
    z-index: 6;

}

.scrolled {
    display: flex;
        align-items: center;
 
    background-color:rgba(0, 0, 0, 0.4);
    height: 126px;
    position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 6;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding-top: 0px;
        padding-bottom: 0px;
    /* Move header out of view when scrolled */
}
.menu{
    cursor: pointer;
    margin-left: 7%;
}


.menu_icon {
    width: 5em;
        height: 4em;
    color: var(--white);
    justify-self: flex-end;

}

.menu_items {
    position: absolute;
    left: 84px;
    top: 130px;
    display: flex;
    flex-direction: column;
    background-color: var(--med-blue);
    width: 267px;
    color: var(--white);
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
/* padding-top: 0.5em; */
}

.close_icon {
    width: 2.5em;
    height:2.5em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    margin-right: 0.5em;
cursor: pointer;

}

span {

    font-family: 'FuturaBT';
    color: var(--white);
    font-size: 26px;
    /* align-self: self-start; */

}

@media (min-width: 1080px) {
.logo{

    width: 14em;
    margin-right: 5.4375em;
}
.hero_section{
     
    height: 122px;
}
.menu_icon {
    width: 4.25em;
    height: 3.625em;
}
span{
    font-size: 26px;
}
.menu{
    padding: 0;
}
.scrolled{
    height: 122px;
    padding-bottom: 0;
}


}
@media (max-width: 48em){
    .logo {
    
            width: 150px;
            height: 46px;
        }
.menu_icon {
    width: 4em;
    height: 3em;
    cursor: pointer;
}
.hero_section{
    height: 60px;
}
.scrolled {
    height: 60px;
    padding: 0;
}
.menu_items {
    position: absolute;
    left: 20px;
    top: 70px;
    display: flex;
    flex-direction: column;
    background-color: var(--med-blue);
    width: 180px;
    color: var(--white);
}
.close_icon {
    width: 27px;
    height: 27px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 5px;
   

}

}
@media (max-width:17.1875em){
    .logo {
    
            width: 130px;
            height: 36px;
        }
    
        .menu_icon {
            width: 3em;
            height: 2em;
        }
                .menu_items {
                    position: absolute;
                    left: 44px;
                    top: 100px;
                    display: flex;
                    flex-direction: column;
                    background-color: var(--med-blue);
                    width:180px;
                    color: var(--white);
                }
}