
.mainContainer {
    margin: 10px;
}

.mainContainer .container {
    padding: 40px 0;
}

.mainContainer .container img {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    width: 70%;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 25px !important;
    line-height: 1;
    opacity: .75;
    color: var(--lighter-blue) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: var(--lighter-blue) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.logos-container {
    background-color:white;
    width: 90vw;
padding-top: 5%;
padding-bottom: 5%;
margin: auto;

}
/* .logos-img-div{
    width: 10%;
    margin-right: 5em;
    margin-left: 5em;
    margin-bottom: 2em;
  

}
.logos-img{
    width: 100%;
}
@media (max-width: 768px){

.logos-img-div {
        width: 15%;
        margin-right: 1em;
        margin-left: 1em;
        margin-bottom: 2em;


    }
} */